<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" sm="8">
          <b-card no-body class="mx-4">
            <b-card-body class="p-4">
              <b-form>
                <h1>Mot de passe oublié</h1>
                <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                <p class="text-muted" v-else>Entrez votre adresse email pour réinitialiser votre mot de passe.</p>

                <c-input container-class="mb-3 required-input" label="Votre adresse email" placeholder="Ex: bob@gmail.com"
                         v-model="email" :state="emailState">
                  Veuillez saisir une adresse email
                </c-input>

                <button-spinner variant="success" block @click="submit" :fetching="fetching">
                  Réinitialiser mon mot de passe
                </button-spinner>

                <b-modal title="Opération réussie" class="modal-success" v-model="successModal"
                         @ok="successModal = false" ok-only ok-variant="success">
                  Un lien de réinitialisation de mot de passe a été envoyé à l'adresse <b>{{email}}</b>
                  Cliquez sur ce lien pour récupérer votre compte.
                </b-modal>
              </b-form>
            </b-card-body>
            <b-card-footer class="p-4">
              <b-row>
                <b-col cols="6">
                  <b-button block variant="primary" type="button" to="/login"><span>Connexion</span></b-button>
                </b-col>
                <b-col cols="6">
                  <b-button block variant="primary" type="button" to="/register"><span>Inscription</span></b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Regex, Api, Toast} from "../../helpers";

  export default {
    name: 'ForgotPassword',
    title: 'PIA - Mot de passe oublié',
    data() {
      return {
        successModal: false,
        email: '',
        submitted: false,
        error: null,
        fetching: false
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      emailState() {
        return !this.submitted || Regex.isEmail(this.email) ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      valid() {
        return Regex.isEmail(this.email)
      },
      submit() {
        this.submitted = true
        if (!this.valid()) return

        this.fetching = true
        Api.post('/forgot-password', { email: this.email })
          .then(res => {
            const success = res.data.data
            if (res.data.status === 'success' && success) {
              this.error = null
              this.successModal = true
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetching = false
          })
      }
    }
  }
</script>
